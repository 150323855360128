import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Product } from 'src/app/core/models/offer/product';
import { ProductSaveInfo, ProductSaveType } from 'src/app/core/models/offer/product-save-info';
import { StoreType } from 'src/app/core/models/offer/store-type';
import { WsApiError } from 'src/app/core/models/ws-api-error';
import { OfferService } from 'src/app/core/services/offer.service';
import { ParseErrorService } from 'src/app/core/services/parse-error.service';
import { DialogAreYouSureComponent } from '../dialog-are-you-sure/dialog-are-you-sure.component';
import { DialogProductComponent } from '../dialog-product/dialog-product.component';
import { HelperPaging } from 'src/app/core/helpers/helper-paging';
import { DialogFulfillableProductComponent } from '../dialog-fulfillable-product/dialog-fulfillable-product.component';
import { ResultsPaging } from 'src/app/core/models/results-paging';
import { PageCount } from 'src/app/core/models/page-count';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {

// TODO:  See http://offer-dev.workscale.io/api/swagger/ui#/


  @Input() public storeTypeCode: string;
  //@Output() evaluatorCancelEvent = new EventEmitter();

  working: boolean;
  errorMessage: string;
  errorDetail: WsApiError;

  products: Product[];
  giftFilterDisplay: string;
  storeTypes: StoreType[];

  productSearchValue: string;
  areResultsFromSearch: boolean;

  // TODO:  Export to enum
  PREV = 'prev';
  NEXT = 'next';

  // Paging vars
  pagingResults: ResultsPaging[];
  pagingCount: PageCount;

  constructor(
    private offerService: OfferService,
    public paging: HelperPaging,
    private datePipe: DatePipe,
    private parseErrorService: ParseErrorService,
    public dialog: MatDialog) { }

  ngOnInit(): void {
    this.working = false;
    this.errorMessage = '';

    this.giftFilterDisplay = '';
    this.productSearchValue = '';
    this.areResultsFromSearch = false;

    // Init paging info
    this.pagingResults = this.paging.resultsPagingInit();
    this.pagingCount = this.paging.pageCountInit();

    this.getProducts(this.storeTypeCode);
    this.getStoreTypes();
  }

  getProducts(
    storeTypeCode: string,
    searchQuery: string = '',
    continuationToken: string = '',
    pageDirection: string = ''): void {
    this.working = true;
    this.errorMessage = '';

    if (continuationToken === null || continuationToken === 'FIRST') {
      continuationToken = '';
    }

    this.offerService.getProducts(storeTypeCode, searchQuery, continuationToken).subscribe({
      next: (response: Product[] | any) => {
        this.products = response?.items;

        // Check to see if paging logic is needed
        this.pagingCount.showResultsPaging = this.paging.isPagingNeeded(response, this.pagingCount.currentPageCount);

        if (this.pagingCount.showResultsPaging) {
          // Get paging values
          this.paging.getPagingInfo(pageDirection, continuationToken, response, this.pagingCount, this.pagingResults);
        }

        this.working = false;
      },
      error: (err: any) => {
        this.errorDetail = this.parseErrorService.getFullApiError(err);
        this.errorMessage = this.errorDetail.errorMessage;
        this.working = false;
      }
    });

  }

  getStoreTypes(): void {
    this.offerService.getStoreTypes().subscribe({
      next: (response: StoreType[] | any) => {
        this.storeTypes = response;
      },
      error: (err: any) => {
        this.errorDetail = this.parseErrorService.getFullApiError(err);
        this.errorMessage = this.errorDetail.errorMessage;
      }
    });
  }

  getAmountDisplay(amount: number, currencyCode: string): string {
    const currencyPipe = new CurrencyPipe('en-US');

    if (currencyCode) {
      if (currencyCode === 'USD') {
        return currencyPipe.transform(amount, currencyCode);
      }

      if (currencyCode === 'PTS') {
        return `${(amount).toLocaleString('en-us', {minimumFractionDigits: 0})} PTS`;
        //return currencyPipe.transform(amount, currencyCode);
      }
    }

    return amount.toString();
  }

  searchValidator(e: Event): void {
    const pattern = new RegExp(/[^\w .-]+$/g);
    const validatatedValue: string = this.productSearchValue.replace(pattern, '');

    //(e.currentTarget as HTMLInputElement).value = validatatedValue;
    this.productSearchValue = validatatedValue;
  }

  searchProducts(e: Event): void {
    if (this.productSearchValue !== '') {
      const query = `Name=${this.productSearchValue}&Name.Evaluation=StartsWith`;

      // Set this boolean, so we can show the clear results button
      this.areResultsFromSearch = true;

      this.getProducts(this.storeTypeCode, query);
    }
  }

  clearSearchResults(): void {
    this.areResultsFromSearch = false;
    this.productSearchValue = '';
    this.getProducts(this.storeTypeCode);
  }

  isSearchButtonDisabled(): boolean {
    if (this.productSearchValue === '') {
      return true;
    }
    return false;
  }

  pageProducts(continuationToken: string, pageDirection: string): void {
    if (continuationToken === 'FIRST') {
      continuationToken = '';
    }
    this.getProducts(this.storeTypeCode, '', continuationToken, pageDirection);  // page direction expects NEXT or PREV
  }

  openProductDialog(selectedProduct: Product, newProduct: boolean): void {
    const dialogRef = this.dialog.open(DialogProductComponent, {
      autoFocus: false,
      height: 'calc(100vh - 80px)',
      maxWidth: '96vw',
      width: '802px',
      position: { top: '45px' },
      panelClass: 'pummelo-modal-header-footer',
      data: {
        selectedProduct: selectedProduct,
        newProduct: newProduct,
        storeTypes: this.storeTypes,
        currentStoreTypeCode: this.storeTypeCode,
        message: 'SAMPLE MESSAGE'
      }
    });

    dialogRef.afterClosed().subscribe((result: ProductSaveInfo | any) => {
      if (result) {  // result === '' means cancel button was clicked
        if (result.saveType === ProductSaveType.NEW_PRODUCT) {
          // Add the product to the array
          this.products.unshift(result.product);

          // Update paging data
          this.pagingCount.countEnd++;
          this.pagingCount.countTotal = this.pagingCount.countTotal === 'many' ? 'many' : this.pagingCount.countEnd.toString();
        } else {
          // Update the existing rule
          const productToUpdate: Product = this.products.find(x => x.key === result.existingProductKey);
          if (productToUpdate) {
            // Update product items
            if (result.product.key) {
              productToUpdate.key = result.product.key;
            }
            if (result.product.name || productToUpdate.name) {
              productToUpdate.name = result.product.name;
            }
            if (result.product.amount) {
              productToUpdate.amount = result.product.amount;
            }
            if (result.product.currencyCode || productToUpdate.currencyCode) {
              productToUpdate.currencyCode = result.product.currencyCode;
            }
            if (result.product.manufacturerCode || productToUpdate.manufacturerCode) {
              productToUpdate.manufacturerCode = result.product.manufacturerCode;
            }
            if (result.product.productCategoryCode || productToUpdate.productCategoryCode) {
              productToUpdate.productCategoryCode = result.product.productCategoryCode;
            }
            if (result.product.description || productToUpdate.description) {
              productToUpdate.description = result.product.description;
            }
            if (result.product.status) {
              productToUpdate.status = result.product.status;
            }
            if (result.product.images) {
              productToUpdate.images = result.product.images;
            }
            if (result.product.tags) {
              productToUpdate.tags = result.product.tags;
            }
          }
        }
      }
    });
  }

  openFulfillableProductDialog(): void {
    const storeTypeKey = this.storeTypes.find(x => x.code === this.storeTypeCode)?.key;
    const allowedCurrencies = this.storeTypes.find(x => x.code === this.storeTypeCode)?.allowedCurrencies;
    const storeTypeName = this.storeTypes.find(x => x.code === this.storeTypeCode)?.name;

    // TODO:  Add the product to the list of fulfillable products
    const dialogRef = this.dialog.open(DialogFulfillableProductComponent, {
      autoFocus: false,
      height: 'calc(100vh - 80px)',
      maxWidth: '96vw',
      width: '802px',
      position: { top: '45px' },
      panelClass: 'pummelo-modal-header-footer',
      data: {
        storeTypeKey: storeTypeKey,
        storeTypeCode: this.storeTypeCode,
        storeTypeName: storeTypeName,
        allowedCurrencies: allowedCurrencies,
        message: 'SAMPLE MESSAGE'
      }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {  // result === '' means cancel button was clicked
        if (result.saveType === ProductSaveType.NEW_FULFILLABLE_PRODUCT) {
          // Add the product to the array
          this.products.unshift(result.product);

          // Update paging data
          this.pagingCount.countEnd++;
          this.pagingCount.countTotal = this.pagingCount.countTotal === 'many' ? 'many' : this.pagingCount.countEnd.toString();
        }
      }
    });
  }

  openConfirmProductDeleteDialog(e: Event, productToDelete: Product): void {
    const deleteElement = e.currentTarget as HTMLSpanElement;
    const deleteDiv: HTMLDivElement = deleteElement.parentElement.parentElement.querySelector('.item-delete-overlay');

    const dialogRef = this.dialog.open(DialogAreYouSureComponent, {
      autoFocus: false,
      position: { top: '10vh' },
      maxWidth: '430px',
      width: '430px',
      panelClass: 'are-you-sure',
      data: {
        deleteObjectName: productToDelete.name,
        deleteObjectTypeName: 'product',
        message: 'This product will be permanently deleted from the system.  Are you sure you want to proceed? '
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result !== '' && result.data && result.data.confirmDelete) {  // result === '' means cancel button was clicked

        deleteDiv.classList.add('show-deleting');

        // call the offer service to delete the plan
        this.offerService.deleteProductByKey(productToDelete.key).subscribe({
          next: (response: any) => {
            if (response && response.ok) {
              // update the list of products/gifts on the page
              this.products = this.products.filter(x => x.key !== productToDelete.key);

              // Update paging info
              this.pagingCount.countEnd--;
              this.pagingCount.countTotal = this.pagingCount.countTotal === 'many' ? 'many' : this.pagingCount.countEnd.toString();
            } else {
              // there was a problem with the delete api call, display a generic error
              this.errorMessage = 'There was a problem with the delete request.  Please refresh and try again.'
              deleteDiv.classList.remove('show-deleting');
            }
          },
          error: (err: any) => {
            // Display the error
            this.errorDetail = this.parseErrorService.getFullApiError(err);
            this.errorMessage = this.errorDetail.errorMessage;
            deleteDiv.classList.remove('show-deleting');
          }
        });
      }
    });
  }

}
