import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HelperPaging } from 'src/app/core/helpers/helper-paging';
import { DialogDataFulfillableProduct } from 'src/app/core/models/offer/dialog-data-fulfillable-product';
import { Product } from 'src/app/core/models/offer/product';
import { ProductSaveInfo, ProductSaveType } from 'src/app/core/models/offer/product-save-info';
import { PageCount } from 'src/app/core/models/page-count';
import { ResultsPaging } from 'src/app/core/models/results-paging';
import { WsApiError } from 'src/app/core/models/ws-api-error';
import { OfferService } from 'src/app/core/services/offer.service';
import { ParseErrorService } from 'src/app/core/services/parse-error.service';

@Component({
  selector: 'app-dialog-fulfillable-product',
  templateUrl: './dialog-fulfillable-product.component.html',
  styleUrls: ['../dialog-product/dialog-product.component.scss', './dialog-fulfillable-product.component.scss']
})
export class DialogFulfillableProductComponent implements OnInit {

  working: boolean;
  errorMessage: string;
  errorDetail: WsApiError;

  products: Product[];
  productCount: number;
  showProductDetail: number;

  storeTypeName: string;
  storeTypeKey: string;
  allowedCurrencies: string[];

  // TODO:  Export to enum
  PREV = 'prev';
  NEXT = 'next';

  // Paging vars
  pagingResults: ResultsPaging[];
  pagingCount: PageCount;

  constructor(
    private offerService: OfferService,
    public paging: HelperPaging,
    private parseErrorService: ParseErrorService,
    public dialogRef: MatDialogRef<DialogFulfillableProductComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataFulfillableProduct) { }

  ngOnInit(): void {
    this.working = false;
    this.errorMessage = '';

    this.storeTypeKey = this.data?.storeTypeKey;
    this.allowedCurrencies = this.data?.allowedCurrencies;
    this.storeTypeName = this.data?.storeTypeName;
    this.storeTypeName = this.storeTypeName === 'Virtual Store' ? 'Gift Catalog' : 'Store Catalog';

    // Init paging info
    this.pagingResults = this.paging.resultsPagingInit();
    this.pagingCount = this.paging.pageCountInit();

    this.productCount = 0;

    // Get the fulfillable product data
    // see: http://offer-dev.workscale.io/api/swagger/ui#/Manage%20-%20Tenant%20-%20App/TenantAppManageFulfillableProductsGet
    this.getFulfillableProducts();
  }

  getFulfillableProducts(continuationToken: string = '', pageDirection: string = ''): void {
    this.working = true;
    this.errorMessage = '';

    if (continuationToken === null || continuationToken === 'FIRST') {
      continuationToken = '';
    }

    this.offerService.getFulfillableProducts(continuationToken).subscribe({
      next: (response: Product[] | any) => {
        this.products = response?.items;

        this.productCount = this.products.length;

        // Check to see if paging logic is needed
        this.pagingCount.showResultsPaging = this.paging.isPagingNeeded(response, this.pagingCount.currentPageCount);

        if (this.pagingCount.showResultsPaging) {
          // Get paging values
          this.paging.getPagingInfo(pageDirection, continuationToken, response, this.pagingCount, this.pagingResults);
        }

        this.working = false;
      },
      error: (err: any) => {
        this.errorDetail = this.parseErrorService.getFullApiError(err);
        this.errorMessage = this.errorDetail.errorMessage;
        this.working = false;
      }
    });
  }

  addFulfillableProduct(productToAdd: Product): void {
    // TODO:  Add the data to the product list on the main page and close the dialog

    const productSaveInfo: ProductSaveInfo = {
      product: productToAdd,
      saveType: ProductSaveType.NEW_FULFILLABLE_PRODUCT
    };

    this.dialogRef.close(productSaveInfo);
  }

  cancelAddProduct(): void {
    this.showProductDetail = -1;
  }

  toggleProductActive(productIndex: number): void {
    if (this.showProductDetail === productIndex) {
      //this.showProductDetail = -1;
      // Do nothing for now
    } else {
      this.showProductDetail = productIndex;
    }
  }

  pageProducts(continuationToken: string, pageDirection: string): void {
    if (continuationToken === 'FIRST') {
      continuationToken = '';
    }
    this.getFulfillableProducts(continuationToken, pageDirection);  // page direction expects NEXT or PREV
  }

}
