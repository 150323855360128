import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NameSort } from 'src/app/core/enums/name-sort';
import { HelperDobService } from 'src/app/core/helpers/helper-dob.service';
import { HelperNameService } from 'src/app/core/helpers/helper-name.service';
import { AppUser } from 'src/app/core/models/app-user';
import { AppUsersResponse } from 'src/app/core/models/app-users-response';
import { DateOfBirth } from 'src/app/core/models/date-of-birth';
import { Name } from 'src/app/core/models/name';
import { ResultsPaging } from 'src/app/core/models/results-paging';
import { UserApp } from 'src/app/core/models/user-apps';
import { WsApiError } from 'src/app/core/models/ws-api-error';
import { ParseErrorService } from 'src/app/core/services/parse-error.service';
import { UserService } from 'src/app/core/services/user.service';
import { environment } from 'src/environments/environment';
import { DialogUserNewComponent } from '../_shared/dialog-user-new/dialog-user-new.component';
import { DialogUserComponent } from '../_shared/dialog-user/dialog-user.component';
import { DialogUserOLDComponent } from '../_shared/dialog-user_OLD/dialog-user_OLD.component';
import { HelperPaging } from 'src/app/core/helpers/helper-paging';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  working: boolean;
  workingChildren: boolean;
  appUsers: Array<AppUser> = [];
  childUsers: AppUser[];
  childUsersIndex: number;
  errorMessage: string;
  errorDetail: WsApiError;
  selectedUser: number;
  appKey: string;
  userSearchValue: string;
  areResultsFromSearch: boolean;

  PREV = 'prev';
  NEXT = 'next';
  currentSortCode: NameSort;

  constructor(
    private userService: UserService,
    private nameHelper: HelperNameService,
    public paging: HelperPaging,
    private dobHelper: HelperDobService,
    private cdr: ChangeDetectorRef,
    private parseErrorService: ParseErrorService,
    public dialog: MatDialog) { }

  ngOnInit(): void {
    this.working = false;
    this.workingChildren = false;
    this.selectedUser = -1;
    this.childUsersIndex = -1;

    this.paging.resultsPaging = [{ continuationToken: '', pageNumber: 1 }];
    this.paging.pageCount = {
      showResultsPaging: false,
      countStart: 0,
      countEnd: 0,
      countTotal: '0',
      currentPageCount: 0
    };

    this.appKey = environment.appKey;
    this.userSearchValue = '';
    this.areResultsFromSearch = false;

    this.getAppUsers();
  }

  getAppUsers(
    continuationToken: string = '',
    pageDirection: string = '',
    sortCode: string = 'SORT_FNLN',
    searchQuery: string = ''): void {
    this.working = true;
    this.currentSortCode = sortCode.replace('SORT_', '') === 'LNFN' ? NameSort.LNFN : NameSort.FNLN;

    if (continuationToken === null) {
      continuationToken = '';
    }

    if (continuationToken === 'FIRST') {
      continuationToken = '';
    }

    this.userService.getAppUsersManage(continuationToken, sortCode, 'Customer', searchQuery).subscribe({
      next: (response: AppUsersResponse | any) => {
        this.appUsers = response?.items;

        // Check to see if paging logic is needed
        this.paging.pageCount.showResultsPaging = this.paging.resultsPagingNeeded(response, this.paging.pageCount.currentPageCount);

        if (this.paging.pageCount.showResultsPaging) {
          // Get paging values
          this.paging.calculatePagingValue(pageDirection, response, continuationToken);
        } else {
          this.paging.pageCount.countTotal = this.appUsers.length.toString();
        }

        this.working = false;
      },
      error: (err: any) => {
        this.errorDetail = this.parseErrorService.getFullApiError(err);
        this.errorMessage = this.errorDetail.errorMessage;
        this.working = false;
      }
    });
  }

  updateUserSort(e: Event): void {
    const radioButton: HTMLInputElement = e.currentTarget as HTMLInputElement;
    let sortValue = '';
    if (radioButton.id === 'firstName' && radioButton.checked) {
      sortValue = 'SORT_FNLN';
    }
    if (radioButton.id === 'lastName' && radioButton.checked) {
      sortValue = 'SORT_LNFN';
    }
    this.areResultsFromSearch = false;
    this.getAppUsers('', '', sortValue);
  }

  searchUsers(e: Event): void {
    const button: HTMLInputElement = e.currentTarget as HTMLInputElement;
    const searchBox: HTMLInputElement = button.parentElement.querySelector('[name=search]');
    const searchText: string = searchBox.value ? searchBox.value : '';

    // Get sort value first
    var sort = this.getSortValue();

    // Can only search on either first name or last name (not both...yet)
    // So we will search on the same value that we are sorting on (first or last)
    if (searchText !== '') {
      let query: string;
      if (sort === '' || sort === 'SORT_FNLN') {
        query = `&Info.Name.First=${searchText}&Info.Name.First.Evaluation=StartsWith`;
      } else {
        query = `&Info.Name.Last=${searchText}&Info.Name.Last.Evaluation=StartsWith`;
      }

      // Set this boolean, so we can show the clear results button
      this.areResultsFromSearch = true;

      this.getAppUsers('', '', sort, query);
    }
  }

  clearSearchResults(): void {
    this.areResultsFromSearch = false;
    this.userSearchValue = '';
    this.getAppUsers('', '', this.getSortValue());
  }

  getSortValue(): string {
    // const sort: string = this.currentSortCode ? `SORT_${this.currentSortCode.toString()}` : '';
    let sortValue: string;

    if (this.currentSortCode) {
      if (this.currentSortCode === (NameSort.FNLN as NameSort)) {
        sortValue = 'SORT_FNLN';
      } else {
        sortValue = 'SORT_LNFN';
      }
    } else {
      sortValue = '';
    }

    return sortValue;
  }

  searchValidator(e: Event): void {
    //const pattern: string = '[^([ \u00c0-\u01ffa-zA-Z\'\-])+$]';
    const pattern = new RegExp(/[^\w .-]+$/g);
    // const validatatedValue: string = (e.currentTarget as HTMLInputElement).value.replace(pattern, '');
    const validatatedValue: string = this.userSearchValue.replace(pattern, '');

    //(e.currentTarget as HTMLInputElement).value = validatatedValue;
    this.userSearchValue = validatatedValue;
  }

  isSearchButtonDisabled(): boolean {
    if (this.userSearchValue === '') {
      return true;
    }
    return false;
  }

  getChildUsers(parentKey: string): void {
    this.workingChildren = true;

    this.userService.getLinkedUsersByParentKey(parentKey).subscribe({
      next: (response: AppUser[] | any) => {
        this.childUsers = response;

        this.workingChildren = false;
      },
      error: (err: any) => {
        this.errorMessage = this.parseErrorService.parseApiError(err);
        this.working = false;
      }
    });
  }

  getAppInfo(user: AppUser): UserApp {
    return user.apps?.filter(x => x.key === this.appKey)[0];
  }

  toggleSelectedUser(userIndex: number, parentUserKey: string): void {
    if (this.selectedUser === userIndex) {
      // already selected, so this is a click to close that section
      this.selectedUser = -1;
      this.workingChildren = false;
    } else {
      this.selectedUser = userIndex;
      if (this.selectedUser !== this.childUsersIndex) {
        // The childUsersIndex stores the current index value for the current childUsers array.  This
        // only changes when the user selects a new row/index of users.  This way the API only gets called
        // when a new user is selected and we reduce the number of API calls
        this.childUsersIndex = userIndex;
        this.childUsers = [];
        this.workingChildren = true;
        this.getChildUsers(parentUserKey);
      }
    }
  }

  pageUsers(continuationToken: string, pageDirection: string): void {
    if (continuationToken === 'FIRST') {
      continuationToken = '';
    }
    this.getAppUsers(continuationToken, pageDirection);  // page direction expects NEXT or PREV
  }

  /**
   *
   * Calls name display helper service
   *
   * @param name NAME object used to create display name
   * @returns string, formatted name
   */
  fullNameDisplay(name: Name, nullPlaceholder: string = '', sortCode: NameSort = NameSort.FNLN): string {
    return this.nameHelper.nameDisplay(name, sortCode) ?? nullPlaceholder;
  }

  openUserDialog(user: AppUser): void {
    const dialogRef = this.dialog.open(DialogUserComponent, {
      autoFocus: false,
      //height: '87vh',  // 94vh + 3vh (margin top), leaves 3vh for margin bottom
      height: 'calc(100vh - 80px)',
      maxWidth: '96vw',
      width: '820px',
      //position: { right: '3vh', top: '3vh' },
      position: { top: '45px' },
      panelClass: 'user-modal',
      data: {
        user: user,
        message: 'SAMPLE MESSAGE',
        isNewUser: user === null ? true : false
      }
    });

    dialogRef.componentInstance.userSaveEvent.subscribe((user: AppUser) => {
      // Find the user in this.appUsers array and update the AppUser so we don't have to re-run the entire query
      const index = this.appUsers.findIndex(x => x.key === user.key);
      this.appUsers[index] = user;
      this.cdr.detectChanges();
    });

    dialogRef.componentInstance.newLinkedUserSaveEvent.subscribe((user: AppUser) => {
      // Add the new user to the appUsers list (under the new parent)
      this.appUsers.unshift(user);

      // Sort the array appropriately
      if (this.currentSortCode === NameSort.FNLN) {
        this.appUsers.sort((a, b) => a.info.name.first.localeCompare(b.info.name.first));
      } else {
        this.appUsers.sort((a, b) => a.info.name.last.localeCompare(b.info.name.last));
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result !== '' && result.data) {  // result === '' means cancel button was clicked

        // Update user data, if it has been closed
        if (result.data.user) {
          // Update the user in the user collection
          let updatedUser = this.appUsers.find(x => x.key === result.data.user.key);
          if (updatedUser) {
            updatedUser = result.data.user;
          }
        }

        console.log(`Dialog result: ${result.data?.message}`);
      }
    });
  }

  openNewUserDialog(): void {
    const dialogRef = this.dialog.open(DialogUserNewComponent, {
      autoFocus: false,
      height: 'calc(100vh - 80px)',
      maxWidth: '96vw',
      width: '650px',
      position: { top: '45px' },
      panelClass: 'pummelo-modal-header-footer',
      data: {
        dialogWidth: '650px',
        message: ''
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result !== '' && result.data) {  // result === '' means cancel button was clicked

        // Update user data, if it has been closed
        if (result.data.newUser) {
          // Open the User dialog for this user
          this.appUsers.unshift(result.data.newUser);
          const newAppUser: AppUser = result.data.newUser;
          this.openUserDialog(newAppUser);
        }

        console.log(`Dialog result: ${result.data?.message}`);
      }
    });
  }

}
