import { Injectable } from '@angular/core';
import { NameSort } from '../enums/name-sort';
import { Name } from '../models/name';

@Injectable({
  providedIn: 'root'
})
export class HelperNameService {

  constructor() { }

  public nameDisplay(name: Name, sortType: NameSort = NameSort.FNLN, omitMiddle: boolean = false): string {
    if (!name) {
      return undefined;
    }

    //let display: string = name.title ?? '';
    let display = '';
    if (sortType === NameSort.FNLN) {
      display += name.first ? display.length > 0 ? ` ${name.first}` : name.first : '';
      display += omitMiddle === false ? name.middle ? display.length > 0 ? ` ${name.middle}` : name.middle : '' : '';
      display += name.last ? display.length > 0 ? ` ${name.last}` : name.last : '';
      display += name.suffix ? display.length > 0 ? ` ${name.suffix}` : name.suffix : '';
    } else {
      display += name.last ? display.length > 0 ? `${name.last}` : name.last : '';
      display += ', '; // Not sure why it won't let me add it in the line above
      display += name.first ? display.length > 0 ? ` ${name.first}` : name.first : '';
      display += omitMiddle === false ? name.middle ? display.length > 0 ? ` ${name.middle}` : name.middle : '' : '';
      display += name.suffix ? display.length > 0 ? ` ${name.suffix}` : name.suffix : '';
    }

    return display;
  }

}
