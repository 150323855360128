import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './modules/_layout/header/header.component';
import { SidebarComponent } from './modules/_layout/sidebar/sidebar.component';
import { DashboardComponent } from './modules/dashboard/dashboard.component';
import { LoginComponent } from './modules/login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { authInterceptorProviders } from './core/helpers/auth.interceptor';
import { errorInterceptorProviders } from './core/helpers/error.interceptor';
import { AuthService } from './core/services/auth.service';
import { AuthGuard } from './core/helpers/auth.guard';
import { HttpClientModule } from '@angular/common/http';
import { UsersComponent } from './modules/users/users.component';
import { JwtModule } from '@auth0/angular-jwt';
import { LocalStorageService } from './core/services/local-storage.service';
import { appInitializer } from './core/helpers/app.initializer';
import { FooterComponent } from './modules/_layout/footer/footer.component';
import { LoadingComponent } from './modules/_shared/loading/loading.component';
import { ParentUsersPipe } from './core/pipes/parent-users.pipe';
import { ChildUsersPipe } from './core/pipes/child-users.pipe';
import { DatePipe } from '@angular/common';
import { UserComponent } from './modules/user/user.component';
import { FilterSelfPipe } from './core/pipes/filter-self.pipe';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DialogUserOLDComponent } from './modules/_shared/dialog-user_OLD/dialog-user_OLD.component';
import { RewardsComponent } from './modules/rewards/rewards.component';
import { TasksComponent } from './modules/rewards/tasks/tasks.component';
import { PlansComponent } from './modules/rewards/plans/plans.component';
import { ErrorDisplayComponent } from './modules/_shared/error-display/error-display.component';
import { BitColorHelper } from './core/helpers/bit-color';
import { ChooseAvatarComponent } from './modules/_shared/choose-avatar/choose-avatar.component';
import { RulesComponent } from './modules/rewards/rules/rules.component';
import { RuleTypesComponent } from './modules/_shared/rule-types/rule-types.component';
import { EvaluatorComponent } from './modules/_shared/evaluator/evaluator.component';
import { DialogRuleComponent } from './modules/_shared/dialog-rule/dialog-rule.component';
import { DialogAreYouSureComponent } from './modules/_shared/dialog-are-you-sure/dialog-are-you-sure.component';
import { DialogUserComponent } from './modules/_shared/dialog-user/dialog-user.component';
import { PlanComponent } from './modules/rewards/plan/plan.component';
import { DialogPlanComponent } from './modules/_shared/dialog-plan/dialog-plan.component';
import { TaskComponent } from './modules/rewards/task/task.component';
import { ChooseBadgeComponent } from './modules/_shared/choose-badge/choose-badge.component';
import { LinkedUsersComponent } from './modules/_shared/linked-users/linked-users.component';
import { UserMilestonesComponent } from './modules/_shared/user-milestones/user-milestones.component';
import { UserTasksComponent } from './modules/_shared/user-tasks/user-tasks.component';
import { OrdersComponent } from './modules/orders/orders.component';
import { UserActivitySummaryComponent } from './modules/_shared/user-activity-summary/user-activity-summary.component';
import { NgChartsModule } from 'ng2-charts';
import { UserBalanceComponent } from './modules/_shared/user-balance/user-balance.component';
import { StatusIconsComponent } from './modules/_shared/status-icons/status-icons.component';
import { GiftCatalogComponent } from './modules/gift-catalog/gift-catalog.component';
import { DialogProductComponent } from './modules/_shared/dialog-product/dialog-product.component';
import { UserPlanAddComponent } from './modules/_shared/user-plan-add/user-plan-add.component';
import { UserInfoComponent } from './modules/_shared/user-info/user-info.component';
import { SvgHelperComponent } from './modules/_shared/svg-helper/svg-helper.component';
import { LinkedUsersAddComponent } from './modules/_shared/linked-users-add/linked-users-add.component';
import { DialogUserNewComponent } from './modules/_shared/dialog-user-new/dialog-user-new.component';
import { ProductsComponent } from './modules/_shared/products/products.component';
import { StoreCatalogComponent } from './modules/store-catalog/store-catalog.component';
import { UserChallengeComponent } from './modules/_shared/user-challenge/user-challenge.component';
import { ForgotPasswordComponent } from './modules/login/forgot-password/forgot-password.component';
import { FilterOrderStatusPipe } from './core/pipes/filter-order-status.pipe';
import { EditPlanTaskComponent } from './modules/rewards/edit-plan-task/edit-plan-task.component';
import { EditPlanChallengeComponent } from './modules/rewards/edit-plan-challenge/edit-plan-challenge.component';
import { EditPlanMilestoneComponent } from './modules/rewards/edit-plan-milestone/edit-plan-milestone.component';
import { HelperImage } from './core/helpers/helper-image';
import { HelperPaging } from './core/helpers/helper-paging';
import { HelperImageCompression } from './core/helpers/helper-image-compression';
import { QrCodeComponent } from './modules/qr-code/qr-code.component';
import { AdminUsersComponent } from './modules/admin/admin-users/admin-users.component';
import { DialogUserAdminNewComponent } from './modules/_shared/dialog-user-admin-new/dialog-user-admin-new.component';
import { DialogUserAdminComponent } from './modules/_shared/dialog-user-admin/dialog-user-admin.component';
import { CompleteRegistrationComponent } from './modules/complete-registration/complete-registration.component';
import { RegisterComponent } from './modules/register/register.component';
import { RegistrationHeaderComponent } from './modules/_shared/registration-header/registration-header.component';
import { MerchantDetailsComponent } from './modules/register/merchant-details/merchant-details.component';
import { RegisterCompanyComponent } from './modules/register/register-company/register-company.component';
import { CompanyContactComponent } from './modules/register/company-contact/company-contact.component';
import { RegisterCompleteComponent } from './modules/register/register-complete/register-complete.component';
import { RegisterPlanComponent } from './modules/register/register-plan/register-plan.component';
import { DialogFulfillableProductComponent } from './modules/_shared/dialog-fulfillable-product/dialog-fulfillable-product.component';
import { UserPlanAddToExistingComponent } from './modules/_shared/user-plan-add-to-existing/user-plan-add-to-existing.component';
import { FulfillableProductFormComponent } from './modules/_shared/fulfillable-product-form/fulfillable-product-form.component';
import { DialogOrderComponent } from './modules/_shared/dialog-order/dialog-order.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SidebarComponent,
    DashboardComponent,
    LoginComponent,
    UsersComponent,
    FooterComponent,
    LoadingComponent,
    ParentUsersPipe,
    ChildUsersPipe,
    UserComponent,
    FilterSelfPipe,
    DialogUserOLDComponent,
    RewardsComponent,
    TasksComponent,
    PlansComponent,
    ErrorDisplayComponent,
    ChooseAvatarComponent,
    RulesComponent,
    RuleTypesComponent,
    EvaluatorComponent,
    DialogRuleComponent,
    DialogAreYouSureComponent,
    DialogUserComponent,
    PlanComponent,
    DialogPlanComponent,
    TaskComponent,
    ChooseBadgeComponent,
    LinkedUsersComponent,
    UserMilestonesComponent,
    UserTasksComponent,
    OrdersComponent,
    UserActivitySummaryComponent,
    UserBalanceComponent,
    StatusIconsComponent,
    GiftCatalogComponent,
    DialogProductComponent,
    UserPlanAddComponent,
    UserInfoComponent,
    SvgHelperComponent,
    LinkedUsersAddComponent,
    DialogUserNewComponent,
    ProductsComponent,
    StoreCatalogComponent,
    UserChallengeComponent,
    ForgotPasswordComponent,
    FilterOrderStatusPipe,
    EditPlanTaskComponent,
    EditPlanChallengeComponent,
    EditPlanMilestoneComponent,
    QrCodeComponent,
    AdminUsersComponent,
    DialogUserAdminNewComponent,
    DialogUserAdminComponent,
    CompleteRegistrationComponent,
    RegisterComponent,
    RegistrationHeaderComponent,
    MerchantDetailsComponent,
    RegisterCompanyComponent,
    CompanyContactComponent,
    RegisterCompleteComponent,
    RegisterPlanComponent,
    DialogFulfillableProductComponent,
    UserPlanAddToExistingComponent,
    FulfillableProductFormComponent,
    DialogOrderComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    NgChartsModule,
    MatDialogModule,
    MatMenuModule,
    MatIconModule,
    JwtModule.forRoot({
      config: {
        tokenGetter:  () => localStorage.getItem('access_token')
      }
    })
  ],
  providers: [
    AuthService,
    AuthGuard,
    DatePipe,
    BitColorHelper,
    HelperImage,
    HelperImageCompression,
    HelperPaging,
    authInterceptorProviders,
    errorInterceptorProviders,
    { provide: APP_INITIALIZER, useFactory: appInitializer, multi: true, deps: [AuthService, LocalStorageService] },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
