import { DatePipe, KeyValue } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { OrderStatus } from 'src/app/core/enums/order-status';
import { HelperPaging } from 'src/app/core/helpers/helper-paging';
import { Order } from 'src/app/core/models/order/order';
import { OrderStatusUpdate } from 'src/app/core/models/order/order-status-update';
import { PageCount } from 'src/app/core/models/page-count';
import { ResultsPaging } from 'src/app/core/models/results-paging';
import { WsApiError } from 'src/app/core/models/ws-api-error';
import { OfferService } from 'src/app/core/services/offer.service';
import { OrderService } from 'src/app/core/services/order.service';
import { ParseErrorService } from 'src/app/core/services/parse-error.service';
import { DialogOrderComponent } from '../_shared/dialog-order/dialog-order.component';
import { SaveInfo } from 'src/app/core/models/save-info';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit {

  working: boolean;
  savingOrder: number;
  errorMessage: string;
  errorDetail: WsApiError;

  orders: Order[];
  orderFilterDisplay: string;
  orderSearchValue: string;
  areResultsFromSearch: boolean;
  currentOrderFilter: string;

  // TODO:  Export to enum
  PREV = 'prev';
  NEXT = 'next';

  orderStatusEnum = OrderStatus;
  orderStatuses: { key: string; value: string; }[] = Object.entries(OrderStatus).map(([key, value]) => ({ key, value }));

  constructor(
    private offerService: OfferService,
    private orderService: OrderService,
    public paging: HelperPaging,
    private datePipe: DatePipe,
    private parseErrorService: ParseErrorService,
    public dialog: MatDialog) { }

  ngOnInit(): void {
    this.working = false;
    this.savingOrder = -1;
    this.errorMessage = '';

    this.orderFilterDisplay = '';
    this.orderSearchValue = '';
    this.areResultsFromSearch = false;
    this.currentOrderFilter = '';

    this.paging.resultsPaging = [{ continuationToken: '', pageNumber: 1 }];
    this.paging.pageCount = {
      showResultsPaging: false,
      countStart: 0,
      countEnd: 0,
      countTotal: '',
      currentPageCount: 0
    };

    this.getOrders();
  }

  getOrders(
    infoStatusCode: string = '',
    searchQuery: string = '',
    continuationToken: string = '',
    pageDirection: string = ''): void {
    this.working = true;
    this.errorMessage = '';

    if (continuationToken === null || continuationToken === 'FIRST') {
      continuationToken = '';
    }

    this.orderService.getOrders(infoStatusCode, searchQuery, continuationToken).subscribe({
      next: (response: Order[] | any) => {
        this.orders = response.items;

        // Check to see if paging logic is needed
        this.paging.pageCount.showResultsPaging = this.paging.resultsPagingNeeded(response, this.paging.pageCount.currentPageCount);

        if (this.paging.pageCount.showResultsPaging) {
          // Get paging values
          this.paging.calculatePagingValue(pageDirection, response, continuationToken);
        }

        this.working = false;
      },
      error: (err: any) => {
        this.errorDetail = this.parseErrorService.getFullApiError(err);
        this.errorMessage = this.errorDetail.errorMessage;
        this.working = false;
      }
    });
  }

  formatOrderId(id: string): string {
    if (id && id.length > 5) {
      const idParts = id.match(/.{1,5}/g);
      if (idParts.length === 2) {
        return idParts.join('-');
      }
      return id;
    }
    return id;
  }

  searchValidator(e: Event): void {
    const pattern = new RegExp(/[^\w .-]+$/g);
    const validatatedValue: string = this.orderSearchValue.replace(pattern, '');

    //(e.currentTarget as HTMLInputElement).value = validatatedValue;
    this.orderSearchValue = validatatedValue;
  }

  searchOrders(e: Event): void {
    if (this.orderSearchValue !== '') {
      const query = `Id=${this.orderSearchValue.replace('-', '')}&Id.Evaluation=StartsWith`;

      // Set this boolean, so we can show the clear results button
      this.areResultsFromSearch = true;

      this.getOrders(this.currentOrderFilter, query);
    }
  }

  clearSearchResults(): void {
    this.areResultsFromSearch = false;
    this.orderSearchValue = '';
    this.getOrders();
  }

  isSearchButtonDisabled(): boolean {
    if (this.orderSearchValue === '') {
      return true;
    }
    return false;
  }

  pageOrders(continuationToken: string, pageDirection: string): void {
    if (continuationToken === 'FIRST') {
      continuationToken = '';
    }
    this.getOrders('', '', continuationToken, pageDirection);  // page direction expects NEXT or PREV
  }

  refreshOrders(): void {
    let orderFilter = '';

    switch (this.orderFilterDisplay) {
      case 'New':
        orderFilter = 'New';
        break;
      case 'Completed/Received':
        orderFilter = 'Received';
        break;
      case '':
        orderFilter = '';
        break;
    }

    this.currentOrderFilter = orderFilter;
    this.getOrders(orderFilter);
  }

  updateOrderFilter(e: Event, filterValue: string): void {
    const radioButton: HTMLInputElement = e.currentTarget as HTMLInputElement;
    // if (radioButton.id === 'new' && radioButton.checked) {
    //   filterValue = 'New';
    //   this.orderFilterDisplay = 'New';
    // }

    switch (filterValue) {
      case 'New':
        this.orderFilterDisplay = filterValue;
        break;
      case 'Received':
        this.orderFilterDisplay = 'Completed/Received';
        break;
      default:
        this.orderFilterDisplay = '';
        break;
    }

    this.getOrders(filterValue);
  }

  dateDisplay(utcOrderDate: Date, dateFormat: string = 'dd-MMM-yyyy'): string {
    //const utcDateString = this.datePipe.transform(utcOrderDate, 'MM/dd/yyyy HH:mm:ss UTC');
    //const localOrderDate = new Date(utcDateString);
    var localOrderDate = new Date(Date.parse(utcOrderDate.toString()));
    const localNow = new Date();

    const hoursAgo = Math.floor(Math.abs(localNow.getTime() - localOrderDate.getTime()) / 36e5);

    //return this.datePipe.transform(localOrderDate, dateFormat);

    if (hoursAgo < 18) {
      if (hoursAgo < 1) {
        if (Math.floor(Math.abs(localNow.getTime() - localOrderDate.getTime()) / 6e4) < 1) {
          return 'just now';
        } else {
          return `${Math.floor(Math.abs(localNow.getTime() - localOrderDate.getTime()) / 6e4)} ${Math.floor(Math.abs(localNow.getTime() - localOrderDate.getTime()) / 6e4) === 1 ? 'minute' : 'minutes'} ago`;
        }
      } else {
        return `${hoursAgo} ${hoursAgo === 1 ? 'hour' : 'hours'} ago`;
      }


    }
    else if (hoursAgo < 36 && hoursAgo >= 18) {
      return 'yesterday at ' + this.datePipe.transform(localOrderDate, 'h:mm a');
    }
    else {
      return this.datePipe.transform(localOrderDate, dateFormat);
    }
  }

  dateDisplay__OLD(utcOrderDate: Date, dateFormat: string = 'dd-MMM-yyyy'): string {
    const now = new Date();
    const utcNow = new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds(), now.getUTCMilliseconds());

    const utcDateString = this.datePipe.transform(utcOrderDate, 'MM/dd/yyyy HH:mm:ss UTC');
    const localOrderDate = new Date(utcDateString);

    const utcDateStringUTC = this.datePipe.transform(utcOrderDate, 'MM/dd/yyyy HH:mm:ss');
    const utcOrderDateObj = new Date(utcDateStringUTC);

    const hoursAgo = Math.floor(Math.abs(utcNow.getTime() - utcOrderDateObj.getTime()) / 36e5);

    // console.log(`now: ${now}`);
    // console.log(`utcNow: ${utcNow}`);
    // console.log(`utcOrderDate: ${utcOrderDate}`);
    // console.log(`utcOrderDateObj: ${utcOrderDateObj}`);
    // console.log(`localOrderDate: ${localOrderDate}`);
    // console.log(`hoursAgo: ${hoursAgo}`);

    if (hoursAgo < 18) {
      return hoursAgo + ' hours ago';
    }
    else if (hoursAgo < 36 && hoursAgo >= 18) {
      return 'yesterday at ' + this.datePipe.transform(localOrderDate, 'h:mm a');
    }
    else {
      return this.datePipe.transform(localOrderDate, dateFormat);
    }

  }

  orderItemIsAvatar(order: Order): boolean {
    if (order.breakdown[0]?.items[0]?.details[0]?.name?.toLocaleLowerCase().includes('avatar')) {
      return true;
    }
    return false;
  }

  updateStatus(e: Event, orderToUpdate: Order, updatedStatusKey: string, i: number): void {
    this.savingOrder = i;

    const orderStatusUpdate: OrderStatusUpdate = {
      status: updatedStatusKey
    };

    // API PATCH (update template task)
    this.orderService.updateOrderByKey(orderToUpdate.key, orderStatusUpdate).subscribe({
      next: (response: Order | any) => {
        const orderIndex: number = this.orders.map(x => x.key).indexOf(orderToUpdate.key);
        this.orders[orderIndex] = response;

        this.savingOrder = -1;
      },
      error: (err: any) => {
        this.errorDetail = this.parseErrorService.getFullApiError(err);
        this.errorMessage = this.errorDetail.errorMessage;
        this.savingOrder = -1;
      }
    });
  }

  shouldShowStatusDropdown(currentOrderStatus: string): boolean {
    if (currentOrderStatus && currentOrderStatus.length > 0) {
      if (currentOrderStatus.toLocaleUpperCase() === OrderStatus.INCOMPLETE.toLocaleUpperCase() ||
        currentOrderStatus.toLocaleUpperCase() === OrderStatus.COMPLETE.toLocaleUpperCase() ||
        currentOrderStatus.toLocaleUpperCase() === OrderStatus.CANCELLED.toLocaleUpperCase()) {
        return false;
      } else {
        return true;
      }
      // TODO:  If PROCESSING then only allow cancel
    }

    return true;
  }

  getStatusValue(currentOrderStatus: string): string {
    if (currentOrderStatus && currentOrderStatus.length > 0) {
      return this.orderStatuses.find(x => x.key.toLocaleUpperCase() === currentOrderStatus.toLocaleUpperCase())?.value ?? currentOrderStatus;
    }
    return currentOrderStatus;
  }

  openOrderDialog(selectedOrder: Order): void {
    const dialogRef = this.dialog.open(DialogOrderComponent, {
      autoFocus: false,
      height: 'calc(100vh - 80px)',
      maxWidth: '96vw',
      width: '802px',
      position: { top: '45px' },
      panelClass: ['pummelo-modal-header-footer', 'modal-background-shade'],
      data: {
        selectedOrder: selectedOrder,
        message: 'SAMPLE MESSAGE'
      }
    });

    dialogRef.componentInstance.saveOrderEvent.subscribe((updatedOrder: Order) => {
      // Find the order in this.orders array and update the updated order so we don't have to re-run the entire query
      const index = this.orders.findIndex(x => x.key === updatedOrder.key);
      this.orders[index] = updatedOrder;
    });

    dialogRef.afterClosed().subscribe((result: SaveInfo<Order> | any) => {
      if (result) {  // result === '' means cancel button was clicked
        // if (result.saveType === ProductSaveType.NEW_PRODUCT) {
        //   // Add the product to the array
        //   this.products.unshift(result.product);

        //   // Update paging data
        //   this.pagingCount.countEnd++;
        //   this.pagingCount.countTotal = this.pagingCount.countTotal === 'many' ? 'many' : this.pagingCount.countEnd.toString();
        // } else {
        //   // Update the existing rule
        //   const productToUpdate: Product = this.products.find(x => x.key === result.existingProductKey);
        //   if (productToUpdate) {
        //     // Update product items
        //     if (result.product.key) {
        //       productToUpdate.key = result.product.key;
        //     }
        //   }
        // }
      }
    });
  }

  openNewOrderDialog(): void {
    // TODO
  }

}
