import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NameSort } from 'src/app/core/enums/name-sort';
import { HelperNameService } from 'src/app/core/helpers/helper-name.service';
import { AppUser } from 'src/app/core/models/app-user';
import { EventDataLinkedUser } from 'src/app/core/models/event-data-linked-user';
import { Name } from 'src/app/core/models/name';
import { WsApiError } from 'src/app/core/models/ws-api-error';
import { ParseErrorService } from 'src/app/core/services/parse-error.service';
import { UserService } from 'src/app/core/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-linked-users',
  templateUrl: './linked-users.component.html',
  styleUrls: ['./linked-users.component.scss']
})
export class LinkedUsersComponent implements OnInit {

  AVATAR_PATH = 'assets/images/avatars/';
  APP_KEY = environment.appKey;

  @Input() public user: AppUser;
  @Output() viewLinkedUserEvent = new EventEmitter<EventDataLinkedUser>();
  @Output() newLinkedUserSaveEvent = new EventEmitter<AppUser>();
  /*
    isNewUser: boolean;
  */

  linkedUsers: AppUser[];
  parentUser: AppUser;
  siblingUsers: AppUser[];
  hasParent: boolean;
  editLinkedUser: boolean;

  working: boolean;
  errorMessage: string;
  errorDetail: WsApiError;

  constructor(
    private userService: UserService,
    private nameHelper: HelperNameService,
    private parseErrorService: ParseErrorService) { }

  ngOnInit(): void {
    this.working = false;
    this.errorMessage = '';
    this.editLinkedUser = false;

    this.hasParent = this.userHasParent();

    if (this.userHasLinkedUsers()) {
      this.getLinkedUsers(this.user.key);
    }

    if (this.user && this.hasParent) {
      this.getLinkedUserByUserKey(this.user.key, this.user.linkInfo?.parentUserKey);
    }
  }

  getLinkedUsers(parentKey: string): void {
    this.working = true;

    this.userService.getLinkedUsersByParentKey(parentKey).subscribe({
      next: (response: AppUser[] | any) => {
        this.linkedUsers = response;

        this.working = false;
      },
      error: (err: any) => {
        this.errorDetail = this.parseErrorService.getFullApiError(err);
        this.errorMessage = this.errorDetail.errorMessage;
        this.working = false;
      }
    });
  }

  getLinkedUserByUserKey(userKey: string, parentKey: string): void {
    this.working = true;

    //this.userService.getAppUserByKey(parentKey).subscribe({
    this.userService.getLinkedUserByUserKey(userKey).subscribe({
      next: (response: AppUser[] | any) => {
        this.parentUser = response.filter(p => p.key === parentKey)[0];
        this.siblingUsers = response.filter(s => s.key !== parentKey && s.key !== userKey);

        this.working = false;
      },
      error: (err: any) => {
        this.errorDetail = this.parseErrorService.getFullApiError(err);
        this.errorMessage = this.errorDetail.errorMessage;
        this.working = false;
      }
    });
  }

  fullNameDisplay(name: Name, nullPlaceholder: string = ''): string {
    return this.nameHelper.nameDisplay(name, NameSort.FNLN, true) ?? nullPlaceholder;
  }

  getUserAvatar(user: AppUser): string {
    let avatarImg = 'default.png';

    if (user && user.info && user.info.additionalInfo && user.info.additionalInfo['avatar']) {
      avatarImg = user.info.additionalInfo['avatar'];
    }

    return this.AVATAR_PATH + avatarImg;
  }

  getAvatarBg(user: AppUser): string {
    let avatarBg = '#fff';

    if (user && user.info && user.info.additionalInfo && user.info.additionalInfo['avatarColor']) {
      avatarBg = user.info.additionalInfo['avatarColor'];
    }

    return avatarBg;
  }

  isDefaultAvatar(user: AppUser): boolean {
    if (user && user.info && user.info.additionalInfo && user.info.additionalInfo['avatar']) {
      return false;
    }
    return true;
  }

  showAddLinkedUser(): void {
    // Show the add linked user
    this.editLinkedUser = true;

    // Scroll to the add user form into view
    setTimeout(() => {
      document.querySelector('.linked-user-form-container').scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, 100);
  }

  userHasParent(): boolean {
    let hasParent = false;

    hasParent = this.user && this.user.linkInfo && this.user.linkInfo.parentUserKey && this.user.linkInfo.parentUserKey.length > 0 ? true : false;

    return hasParent;
  }

  userHasLinkedUsers(): boolean {
    let hasParent = false;

    hasParent = this.user && this.user.linkInfo?.linkedCount && this.user.linkInfo?.linkedCount > 0 ? true : false;

    return hasParent;
  }

  viewLinkedUser(linkedUser: AppUser, parentUser: AppUser = null): void {
    // Reload dialog data (not reloading, just updating data and calling ngOnInit)
    this.viewLinkedUserEvent.emit({ linkedUser: linkedUser, parentUser: parentUser });

    // Reload this (linked-users) - (not reloading, just updating user and calling ngOnInit)
    this.user = linkedUser;
    this.ngOnInit();
  }

  saveLinkedUser(eventData: AppUser | any): void {  // TODO:  Update eventData data type
    this.editLinkedUser = false;

    // Update parent user with the new linked user count
    if (this.user.linkInfo) {
      this.user.linkInfo.linkedCount++;
    } else {
      this.user.linkInfo = { linkedCount: 1 };
    }

    // Emit the new linked user to the parent component
    this.newLinkedUserSaveEvent.emit(eventData);

    this.getLinkedUsers(this.user.key);
  }

  cancelLinkedUser(): void {
    this.editLinkedUser = false;
  }

}
