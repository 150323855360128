import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { HelperNameService } from 'src/app/core/helpers/helper-name.service';
import { AppUser } from 'src/app/core/models/app-user';
import { Name } from 'src/app/core/models/name';
import { WsApiError } from 'src/app/core/models/ws-api-error';
import { ParseErrorService } from 'src/app/core/services/parse-error.service';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss']
})
export class UserInfoComponent implements OnInit, OnChanges {

  working: boolean;
  errorMessage: string;
  errorDetail: WsApiError;
  saving: boolean;

  editUser: boolean;
  toggleSuffix: boolean;
  userForm: UntypedFormGroup;
  isMinor: boolean;
  months: string[];
  days: number[];

  @Input() public user: AppUser;
  @Input() public isNewUser: boolean;
  @Output() userSaveEvent = new EventEmitter<AppUser>();

  constructor(
    private userService: UserService,
    private nameHelper: HelperNameService,
    private parseErrorService: ParseErrorService,
    private fb: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.working = false;
    this.errorMessage = '';
    this.saving = false;

    this.editUser = false;
    this.toggleSuffix = false;

    this.isMinor = this.user?.info?.isMinor ? this.user.info.isMinor : false;
    this.months = Array(12).fill(0).map((i, idx) => this.getMonth(idx + 1));

    this.createForm();

    this.formChanges();
  }

  ngOnChanges(): void {
    this.editUser = false;
    this.isMinor = this.user?.info?.isMinor ? this.user.info.isMinor : false;
    this.createForm();
    this.formChanges();
  }

  get f(): { [key: string]: AbstractControl } {
    return this.userForm.controls;
  }

  createForm(): void {
    this.userForm = this.fb.group({
      first: [this.user?.info?.name?.first ?? '', this.conditionalValidator('last')],
      middle: [this.user?.info?.name?.middle ?? ''],
      last: [this.user?.info?.name?.last ?? '', this.conditionalValidator('first')],
      suffix: [this.user?.info?.name?.suffix ?? ''],
      emailAddress: [this.user?.emailAddress ?? '', Validators.email],
      year: [this.user?.info?.dateOfBirth?.year ?? '', [Validators.pattern('^[0-9]*$'), Validators.minLength(4), Validators.maxLength(4)]],
      month: [this.user?.info?.dateOfBirth?.month ?? 0],
      day: [this.user?.info?.dateOfBirth?.day ?? 0],
      isMinor: [this.user?.info?.isMinor ?? false]
    });
  }

  conditionalValidator(field: string): ValidatorFn {
    return userForm => {
      if (!userForm.parent) {
        return null;
      }

      const otherControl = userForm.parent.get(field);
      const error: ValidationErrors = userForm.value || otherControl.value ? null : { conditional: { key: 'conditional', error: `this field or ${field} is required` } };

      if ((error && userForm.valid) || (!error && userForm.invalid)) {
        setTimeout(() => {
          otherControl.updateValueAndValidity({ emitEvent: false });
        });
      }
      return error;
    };
  }

  resetFormToDefault(): void {
    this.userForm.reset();
    this.userForm.controls['first'].setValue(this.user?.info?.name?.first ?? '');
    this.userForm.controls['middle'].setValue(this.user?.info?.name?.middle ?? '');
    this.userForm.controls['last'].setValue(this.user?.info?.name?.last ?? '');
    this.userForm.controls['suffix'].setValue(this.user?.info?.name?.suffix ?? '');
    this.userForm.controls['emailAddress'].setValue(this.user?.emailAddress ?? '');
    this.userForm.controls['year'].setValue(this.user?.info?.dateOfBirth?.year ?? '');
    this.userForm.controls['month'].setValue(this.user?.info?.dateOfBirth?.month ?? 0);
    this.userForm.controls['day'].setValue(this.user?.info?.dateOfBirth?.day ?? 0);
    this.userForm.controls['isMinor'].setValue(this.user?.info?.isMinor ?? false);
    this.editUser = false;
  }

  formChanges(): void {
    // Cute little item to make title reflect user changes
    this.f['first'].valueChanges.subscribe({
      next: (val: any) => {
        if (val && this.user && this.user.info && this.user.info.name) {
          this.user.info.name.first = val;
        }
      }
    });

    this.f['middle'].valueChanges.subscribe({
      next: (val: any) => {
        if (val && this.user && this.user.info && this.user.info.name) {
          this.user.info.name.middle = val;
        }
      }
    });

    this.f['last'].valueChanges.subscribe({
      next: (val: any) => {
        if (val && this.user && this.user.info && this.user.info.name) {
          this.user.info.name.last = val;
        }
      }
    });

    this.f['isMinor'].valueChanges.subscribe({
      next: (val: any) => {
        this.isMinor = val;
        if (val && this.user && this.user.info && this.user.info.isMinor) {
          this.user.info.isMinor = val;
        }
      }
    });
  }

  isInvalid(formElement: AbstractControl, name: string = ''): boolean {
    if (formElement.invalid && name === 'dob') {
      return true;
    } else if (formElement.invalid && (formElement.dirty || formElement.touched)) {
      return true;
    }
    return false;
  }

  getError(errors: ValidationErrors, labelName: string = ''): string {
    if (!errors) {
      return '';
    }

    if (errors['required']) {
      return labelName !== '' ? `${labelName} is required` : 'required';
    }

    if (errors['conditional']) {
      return labelName != '' ? `${labelName} is required` : 'required';
    }

    if (errors['minlength']) {
      return `must at least ${errors['minlength']?.requiredLength} chars`;
    }

    if (errors['email']) {
      return 'not a valid email';
    }

    if (errors['pattern']) {
      return 'Numbers only';
    }

    if (errors['invalidDob']) {
      return 'Date of Birth is not a valid date';
    }

    if (errors['min']) {
      return `${labelName} is an invalid year (too short)`;
    }

    if (errors['max']) {
      return `${labelName} is an invalid year (too long)`;
    }

    return 'Error';
  }

  /**
   * TODO:  Probably extract this to a static helper
   * @param index index number
   * @returns month
   */
  public getMonth(index: number) {
    const myDate = new Date();
    myDate.setDate(1);
    myDate.setMonth(index - 1);

    const locale = 'en-us';
    const month = myDate.toLocaleString(locale, { month: 'long' });

    return month;
  }

  public getDays(thisForm: any): number[] {
    const dayCount = !thisForm.year && !thisForm.month ?
      31 :
      this.getDaysInMonth(thisForm.year, thisForm.month);
    return Array(dayCount).fill(0).map((i, idx) => idx + 1);
  }

  public getDaysInMonth(year: number, month: number): number {
    return 32 - new Date(year, month - 1, 32).getDate();
  }

  fullNameDisplay(name: Name, nullPlaceholder: string = ''): string {
    return this.nameHelper.nameDisplay(name) ?? nullPlaceholder;
  }

  isMinorToggle(e: Event): void {
    const currentCheckbox = e.currentTarget as HTMLInputElement;

    if (currentCheckbox.id === 'userIsMinor') {
      this.isMinor = !currentCheckbox.checked;  // Mark it as the opposite, because "checked" is true for adult
    } else {
      this.isMinor = !currentCheckbox.checked;
    }

    this.f['isMinor'].markAsDirty();
    this.f['isMinor'].markAsTouched();

    // TODO:  Possible save this without hitting save button
  }

  saveUser(): void {
    this.saving = true;
    const updatedUser: AppUser = this.buildUserDataFromFormInput();

    // Call the API and check to see if the username is available
    //const suggestedValues: string[] = this.userService.getUsernameSuggestion(usernameValue);
    this.userService.updateUserByKey(this.user.key, updatedUser).subscribe({
      next: (response: AppUser | any) => {
        this.user = response;

        // Update the parent page (USERS) rather than do a reload of that page
        // TODO
        this.userSaveEvent.emit(this.user);

        this.saving = false;
        this.editUser = false;
      },
      error: (err: any) => {
        this.errorMessage = this.parseErrorService.parseApiError(err);
        this.saving = false;
      }
    });

  }

  buildUserDataFromFormInput(): AppUser {
    const user: AppUser = !this.isNewUser ? { ...this.user } : {} as AppUser;

    if (!user.info) {
      user.info = {};
    }

    if (!user.info.name) {
      user.info.name = {};
    }

    user.info.name.first = this.f['first'].value;

    if (this.f['middle']) {
      user.info.name.middle = this.f['middle'].value;
    }

    if (this.f['last']) {
      user.info.name.last = this.f['last'].value;
    }

    if (this.f['suffix']) {
      user.info.name.suffix = this.f['suffix'].value;
    }

    if (this.f['emailAddress']) {
      user.emailAddress = this.f['emailAddress'].value;
    }

    if (this.isInputDOBValidDate()) {
      if (!this.f['year'].value && this.f['month'].value == '0' && this.f['day'].value == '0') {
        // There is an existing DOB and then the user
        // clears it out, then need to clear the DOB
        user.info.dateOfBirth = {};
      }
      else {
        // Update the DOB to the user object
        if (!user.info.dateOfBirth) {
          user.info.dateOfBirth = {};
        }

        user.info.dateOfBirth.year = this.f['year'].value;
        user.info.dateOfBirth.month = this.f['month'].value;
        user.info.dateOfBirth.day = this.f['day'].value;
      }

    }

    user.info.isMinor = this.isMinor;

    /* TODO:  Get this done for new users
    if ((this.f['displayName'].value || this.user?.displayName) &&
      this.f['displayName'].value !== this.user?.displayName) {
      user.displayName = this.f['displayName'].value;
    }
    */

    return user;
  }

  validateDOBInput(): void {
    if (!this.isInputDOBValidDate()) {
      this.f['month'].setErrors({ invalidDob: true });
      this.f['day'].setErrors({ invalidDob: true });
      this.f['year'].setErrors({ invalidDob: true });
    } else {
      this.f['month'].setErrors(null);
      this.f['month'].updateValueAndValidity();
      this.f['day'].setErrors(null);
      this.f['day'].updateValueAndValidity();
      this.f['year'].setErrors(null);
      this.f['year'].updateValueAndValidity();
    }
  }

  isInputDOBValidDate(): boolean {
    // if ALL are empty, then it's valid (null/not required)
    if (!this.f['year'].value && this.f['month'].value == '0' && this.f['day'].value == '0') {
      return true;
    }

    if (!this.f['year'].value || !this.f['month'].value || !this.f['day'].value) {
      return false;
    }

    var dob: Date = new Date(`${this.f['year'].value}-${this.f['month'].value}-${this.f['day'].value}`);

    // TODO:  If DOB puts the user younger than 18, toggle isMinor

    if (isNaN(dob.getTime())) {
      return false;
    }

    return true;
  }

}
